/* jshint esversion: 6 */

import React from 'react';

const LoginModal = ({ handleOnClick, title, text, buttonText, id }) => {

    const [pass, setPass] = React.useState('');

    return (
        /* jshint ignore:start */
        <div id={id} class="modal-window">
        <div>
            <a href="#" title="Close" class="modal-close">Zatvori</a>
            <h1>{title}</h1>
            <div>{text}</div>
            <input type="password" onChange={(e) => {setPass(e.target.value)}} />
            <button className="btn-prebaci margin-top" onClick={() => {handleOnClick(pass)}}>{buttonText}</button>
            </div>
        </div>
         /* jshint ignore:end */
    );
};

export default LoginModal;
