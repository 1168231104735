/* jshint esversion: 6 */

import React from 'react';
import axios from 'axios';
import { updateUser } from '../actions/user';
import { connect } from 'react-redux';
import * as Constants from './Constants';
import LoginModal from './LoginModal';
import keys from '../config/keys.js';

const Login = (props) => {
    const [user, setUser] = React.useState('');
    const [waiters, setWaiters] = React.useState([]);

    const planplus = axios.create({
        baseURL: Constants.BASE_URL,
        auth: {
            username: keys.user,
            password: keys.pass
        }
    });

    /* jshint ignore:start */
    React.useEffect(() => {
        const getWaiters = async () => {
            const data = await planplus.get('https://pp.doubleclick.hr/hr/orders/waiters/', {
                "email": keys.user,
                "password": keys.pass
            });
            setWaiters(data.data.results);
        };

        getWaiters();
    }, []);


    const handleOnClick = async (password) => {

        try {
            const data = await planplus.post('/hr/users/login/', {
                "email": user,
                "password": password
            });
            console.log(data)
            if ('id' in data.data) {
                props.updateUser({
                    id: data.data.id,
                    username: user,
                    password: password, 
                    first_name: data.data.first_name,
                    last_name: data.data.last_name
                });
                props.history.push(`/loading/${data.data.id}`);
            }
        } catch (ex) {
            setUser('');
        }
    };
    /* jshint ignore:end */

    return (
        /* jshint ignore:start */
        <div className="login-wrap">
            <h1 className="title">Orders</h1>
            <h3 className="subtitle margin-bottom">Odaberite korisnika.<span>Za sve nejasnoće obratite se voditelju.</span></h3>
            {waiters.map((waiter) => {
                return <a 
                key={waiter.email}
                onClick={() => {setUser(waiter.email)}}
                className="button-home-2" 
                href="#open-modal-login">
                {`${waiter.first_name.slice(0, 1)}. ${waiter.last_name}`}
                </a>
            })}
            <LoginModal
            handleOnClick={handleOnClick}
            title="Unesite lozinku"
            text={`Unesite lozinku za korisničko ime ${user}`}
            buttonText="Prijavi se"
            id="open-modal-login"
             />
        </div>
        /* jshint ignore:end */
    );
};

export default connect(null, { updateUser })(Login);
